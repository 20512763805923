import Vue from 'vue'
import VueCookies from 'vue-cookies';
import App from './components/App/template.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Carousel from './components/Carousel/template.vue';
import Calculator from './components/Calculator/template.vue';

Vue.use(VueCookies);
Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('component-carousel', Carousel)
Vue.component('component-calculator', Calculator)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
