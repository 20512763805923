export default {
  name: "carousel-page",
  data() {
    return {
        show: true,
    };
  },
  computed: {

    shippers: function () {
        return this.$store.state.shippers;
    },

    selected: function () {
        return this.shippers[0].value;
    },

    lang: function () {
        return this.$store.state.lang;
    },

    sliders: function () {
    return this.$store.state.sliders.map(obj=>({
        id: obj.id,
        url: '../slider/slide'+obj.id+'.jpg',
        caption_url: '/'+this.lang+obj.caption_url,
        caption: obj[this.lang].caption,
        text: obj[this.lang].text,
        button_caption: (typeof obj[this.lang] == 'undefined') ? obj.en["button_caption"] : obj[this.lang]["button_caption"],
    }))
    },

    agents: function () {
    return this.$store.state.agents_nets.map(obj=>({
        id: obj.id,
        img_url: '../img/'+obj.img,
        caption_url: obj.caption_url,
        caption: (typeof obj[this.lang] == 'undefined') ? obj.en.caption : obj[this.lang].caption,
    }))
    },

  },

  methods: {

  },

  created() {

  },
};