import Vue from 'vue';
import VueRouter from 'vue-router';
import Root from './../components/Root/template.vue';

import HomeEn from './../components/En/Home/template.vue';
import HomeRu from './../components/Ru/Home/template.vue';

import AboutUsEn from './../components/En/AboutUS/template.vue';
import AboutUsRu from './../components/Ru/AboutUS/template.vue';

import NeedToKnow from './../components/En/NeedToKnow/template.vue';
import NeedToKnowRu from './../components/Ru/NeedToKnow/template.vue';

import Addons from './../components/En/Addons/template.vue';
import AddonsRu from './../components/Ru/Addons/template.vue';

import NotFound from './../components/NotFound/template.vue';

import PrivacyPolicyEn from './../components/En/PrivacyPolicy/template.vue';
import PrivacyPolicyRu from './../components/Ru/PrivacyPolicy/template.vue';

import NewsEn from './../components/En/News/template.vue';
import NewsRu from './../components/Ru/News/template.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/en/home',
  },
        {
        path: '/en',
        name: 'En',
        component: Root,
        redirect: '/en/home',
        props: { lang: 'en', },
        meta: { title: 'eCargoPlus - freight forwarding company' },
        children :[
            {
              path: 'home',
              component: HomeEn,
              meta: { title: 'eCargoPlus - freight forwarding company' },
            },
            {
              path: 'about-us',
              component: AboutUsEn,
            },
            {
              path: 'need-to-know',
              component: NeedToKnow,
            },
            {
              path: 'addons',
              component: Addons,
            },
            {
              path: 'privacy-policy',
              component: PrivacyPolicyEn,
            },
            {
              path: 'news',
              component: NewsEn,
            },
            {
                path: '*',
                name: 'Not Found',
                component: NotFound
            },
        ],
      },

      {
        path: '/ru',
        name: 'Ru',
        component: Root,
        props: { lang: 'ru', },
        meta: { title: 'eCargoPlus - перевозка грузов | транспортно-экспедиторская компания' },
        children :[
                    {
              path: 'home',
              component: HomeRu,
              meta: { title: 'eCargoPlus - перевозка грузов | транспортно-экспедиторская компания' },
            },
            {
              path: 'about-us',
              component: AboutUsRu,
            },
            {
              path: 'need-to-know',
              component: NeedToKnowRu,
            },
            {
              path: 'addons',
              component: AddonsRu,
            },
            {
              path: 'privacy-policy',
              component: PrivacyPolicyRu,
            },
            {
              path: 'news',
              component: NewsRu,
            },
            {
                path: '*',
                name: 'Not Found',
                component: NotFound
            },
        ],
      },


  {
    path: '*',
    name: 'Not Found',
    component: NotFound
   },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'eCargoPlus - freight forwarding company';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router
