export default {
  props: {
    lang: {
      type: String,
      required: true,
      default: "en",
    },
  },

  data() {
    return {

    };
  },
  computed: {
      page_settings: function () {
        return {
          year: new Date().getFullYear(),
        }
      },

      lang_list: function () {
        return this.$store.state.languages.map(obj => ({ ...obj, flag: "flag-icon flag-icon-"+obj.country }))
      },

      lang_obj: function () {
        return this.lang_list.find(obj=> obj.caption === this.lang)
      },

      nav_list: function () {
        return this.$store.state.all_nav_list[this.lang].filter(i => (typeof i.drop_list == 'undefined'))
      },

      nav_drop_list: function () {
        return this.$store.state.all_nav_list[this.lang].filter(i => (typeof i.drop_list !== 'undefined'))
      },

      contacts: function () {
        return this.$store.state.contacts.map(obj => ({
            id: obj.id,
            country: obj.country,
            caption: (typeof obj[this.lang] == 'undefined') ? obj.en.caption : obj[this.lang].caption,
            address: (typeof obj[this.lang] == 'undefined') ? obj.en.address : obj[this.lang].address,
            emails: obj.emails,
            phones: obj.phones,
            faxes: obj.faxes,
            mobiles: obj.mobiles,
            flag: "flag-icon flag-icon-"+obj.country,
        }))
      },

      menu_list: function () {
        return this.$store.state.sliders.map(obj=>({
            id: obj.id,
            caption_url: '/'+this.lang+obj.caption_url,
            caption: obj[this.lang].caption,
            }))
      },

      cards: function () {
        return this.$store.state.cards.map(obj => ({
            id: obj.id,
            header_url: "/"+this.lang+obj.header_url,
            header: obj[this.lang].header,
            text: obj[this.lang].text,
        }))
      },


    },

  methods: {

  },

  created() {
    this.$store.commit("set_lang",this.lang);

  },
};