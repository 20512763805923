import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'en',

    sliders : [
         {
            id: '1',
            caption_url: '/home#air-freight',
            en: {
                caption: 'Air Freight',
                text: 'Make your air freight order easy and quickly in your Account via our free web-based system.',
                button_caption: "eFreight it...",
            },
            ru: {
                caption: 'Авиаперевозки',
                text: 'Закажите авиаперевозку груза в Вашем Личном кабинете на нашем сайте. Это быстро и просто.',
                button_caption: "Подробнее...",
            },
         },
         {
            id: '2',
            caption_url: '/home#sea-freight',
            en: {
                caption: 'Sea Freight',
                text: "Make your sea freight order easy and quickly in your Account via our free web-based system.",
                button_caption: "eFreight it...",
            },
            ru: {
                caption: 'Морские перевозки',
                text: "Закажите морскую перевозку груза в Вашем Личном кабинете на нашем сайте. Это легко и удобно.",
                button_caption: "Подробнее...",
            },
         },
         {
            id: '3',
            caption_url: '/home#road-freight',
            en: {
                caption: 'Road Freight',
                text: 'Make your European road freight order easy and quickly in your Account via our free web-based system.',
                button_caption: "eFreight it...",
            },
            ru: {
                caption: 'Автоперевозки',
                text: 'Закажите автоперевозку груза по Европе в Вашем Личном кабинете на нашем сайте. Это не сложно и надежно.',
                button_caption: "Подробнее...",
            },
         },
         {
            id: '4',
            caption_url: '/home#cargo-insurance',
            en: {
                caption: 'Cargo Insurance',
                text: 'Insure your cargo by one click in your Account via our free web-based system.',
                button_caption: "Read more...",
            },
            ru: {
                caption: 'Страхование грузов',
                text: 'Застраховать груз одним нажатием клавиши в Вашем Личном кабинете на нашем сайте.',
                button_caption: "Подробнее...",
            },
         },
         {
            id: '5',
            caption_url: '/home#transit-warehousing',
            en:{
                caption: 'Transit Warehousing',
                text: 'Issue the loading lists by one click in your Account via our free web-based system.',
                button_caption: "Read more...",
            },
            ru:{
                caption: 'Складские услуги',
                text: 'Формируйте погрузочные листы одним нажатием клавиши в Вашем Личном кабинете на нашем сайте.',
                button_caption: "Подробнее...",
            },
         },
         {
            id: '6',
            caption_url: '/home#customs-brokerage',
            en: {
                caption: 'Customs Brokerage',
                text: 'Order the issuance of EU export customs declaration in your Account via our free web-based system.',
                button_caption: "Read more...",
            },
            ru: {
                caption: 'Таможенное сопровождение',
                text: 'Закажите выпуск экспортной декларации Евросоюза в Вашем Личном кабинете на нашем сайте.',
                button_caption: "Подробнее...",
            },
         },

         ],

    cards : [
         {
            id: '1',
            header_url: '/addons#consignors',
            en: {
                header: 'Consignors',
                text: 'Be sure, your logistics terms in contract with buyer will be fulfilled',
            },
            ru: {
                header: 'Грузоотправителям',
                text: 'Условия поставки Вашего товара будут выполнены надлежащим образом',
            },
         },
         {
            id: '2',
            header_url: '/addons#freight-forwarders',
            en: {
                header: 'Freight forwarders',
                text: 'Fast execution and favourable rates',
            },
            ru: {
                header: 'Экспедиторам',
                text: 'Вы поручаете - мы выполняем',
            },
         },
         {
            id: '3',
            header_url: '/addons#consignees',
            en: {
                header: 'Consignees',
                text: 'Safe delivery of your goods, in time',
            },
            ru: {
                header: 'Грузополучателям',
                text: 'Доставка Вашего груза в указанные место и время',
            },
         },
         {
            id: '4',
            header_url: '/addons#investors',
            en:{
                header: 'Investors',
                text: 'Invest in future with transport logistics professionals',
            },
            ru:{
                header: 'Инвесторам',
                text: 'Инвестируйте в будущее с профессионалами в области транспортной логистики',
            },
         },
    ],

    all_nav_list : {
            en : [
             {
                id: '1',
                caption: 'About Us',
                caption_url: '/en/about-us',
             },
             {
                id: '2',
                caption: 'News',
                caption_url: '/en/news',

             },
             {
                id: '3',
                caption: 'Careers',
                caption_url: '/en/addons#careers',
             },
             {
                id: '4',
                caption: 'Need To Know',
                caption_url: '/en/need-to-know',
             },
             {
                id: '5',
                caption: 'Contact Us',
                caption_url: '#contact-us',
             },
             ],
            ru : [
             {
                id: '1',
                caption: 'О компании',
                caption_url: '/ru/about-us',
             },
             {
                id: '2',
                caption: 'Новости',
                caption_url: '/ru/news',

             },
             {
                id: '3',
                caption: 'Вакансии',
                caption_url: '/ru/addons#careers',
             },
             {
                id: '4',
                caption: 'Важно знать',
                caption_url: '/ru/need-to-know',
             },
             {
                id: '5',
                caption: 'Контакты',
                caption_url: '#contact-us',
             },
             ],
         },

    languages:[
        {
            id: 1,
            caption_url: '/en/home',
            country: 'gb',
            caption: 'en',
        },
        {
            id: 2,
            caption_url: '/ru/home',
            country: 'ru',
            caption: 'ru',
        },
    ],

    contacts :
    [
        {
            id: '1',
            country: 'pk',
            en : {
                caption: 'Lahore',
                address: '153-Shershah Block, Garden Town, Lahore 54000– Pakistan',
            },
            emails: ['pk.lhe@eCargoPlus.com',],
            phones: ['+92-42-35913935', '+92-42-35913936',],
            faxes: ['+92-42-35913937',],
            mobiles: ['+92 320 261 1973',],
        },
        {
            id: '2',
            country: 'pk',
            en : {
                caption: 'Karachi',
                address: 'Office#408, 4th Floor, c\\926_927 block 2, P.E.C.H.S Muhammad Mustafa Trade Center Tariq Road, 75400, Karachi',
            },
            emails: ['pk.khi@eCargoPlus.com',],
            phones: ['+92-42-35913935',],
            faxes: ['+92-42-35913937',],
            mobiles: ['+92 320 261 1973',],
        },
        {
            id: '3',
            country: 'pk',
            en : {
                caption: 'Sialkot',
                address: 'Office No. 17, 1st Floor, Yousaf Plaza, Defence Road, Sialkot-Pakistan',
            },
            emails: ['pk.skt@eCargoPlus.com',],
            phones: ['+92-52-3259090',],
        },
        {
            id: '4',
            country: 'ru',
            en : {
                caption: 'Moscow',
                address: 'Artekovskaya 1A, 117556 Moscow, Russia',
            },
            ru : {
                caption: 'Москва',
                address: 'РФ, 117556, г.Москва, ул. Артековская, д.1А, офис VII, этаж 4',
            },
            emails: ['ru.mow@eCargoPlus.com',],
            phones: ['+7 499 673 0 338',],
        },
        {
            id: '5',
            country: 'tw',
            en : {
                caption: 'Taipei',
                address: 'Rm. 2, 13F., No.112, Sec. 2, Zhongshan N. Rd., Taipei City 104, Taiwan (R.O.C.)',
            },
            emails: ['tw.taipei@eCargoPlus.com',],
            phones: ['+886 2 2567 1510',],
        },
        {
            id: '6',
            country: 'hk',
            en : {
                caption: 'Hong Kong',
                address: 'Rm 1805, 18/F, International Trade Centre, 11 Sha Tsui Road, Tsuen Wan, N.T., HK',
            },
            emails: ['hk.hkg@eCargoPlus.com',],
            phones: ['+852 36140453',],
            faxes: ['+852 36145699',],
        },
        {
            id: '7',
            country: 'kz',
            en : {
                caption: 'Almaty',
                address: '050063, Almaty, Auezov district, Taugul-1, 14A, Cab.203',
            },
            ru : {
                caption: 'Алматы',
                address: 'РК, 050063, г.Алматы, Ауэзовский район, мкр.Таугуль-1, дом 14А, каб.203',
            },
            emails: ['kz.ala@eCargoPlus.com',],
            phones: ['+7 701 722 53 33',],
        },
        {
            id: '8',
            country: 'cn',
            en : {
                caption: 'Beijing',
                address: 'South Fa Xinzhen smooth road and Shunyu road at the junction, Turn north, turn right at the first crossroads, Shunyi District, Beijing',
            },
            emails: ['cn.pek@eCargoPlus.com',],
            phones: ['+86-18616340321',],
        },
        {
            id: '9',
            country: 'cn',
            en : {
                caption: 'Shanghai',
                address: 'Block 4-3 No.278 Jinhu Road, JinQiao Export Proceeding Zone, Shanghai',
            },
            emails: ['cn.sha@eCargoPlus.com',],
            phones: ['+86-18221375975',],
        },
        {
            id: '10',
            country: 'cn',
            en : {
                caption: 'Guangzhou',
                address: 'Huadong Town Nanxi Industrial Park 6 Warehouse, Huadu Distrct, Guangzhou',
            },
            emails: ['cn.can@eCargoPlus.com',],
            phones: ['+86-18616340321',],
        },
        {
            id: '11',
            country: 'ch',
            en : {
                caption: 'eCargo Switzerland',
                address: 'Brugglistrasse, 2 CH-8852 Altendorf',
            },
            emails: ['ch.info@eCargoPlus.com',],
            phones: ['+41 44 585 35 65',],
        },
    ],

    agents_nets:
    [
        {
            id: 1,
            en: {
                caption: "WCA - Leading the World in Logistics Partnering",
            },
            caption_url: "https://www.wcaworld.com/directory/members/95816",
            img: "wca.png"
        },
        {
            id: 2,
            en: {
                caption: "The International Air Transport Association (IATA) is the trade association for the world’s airlines",
            },
            caption_url: "http://www.iata.org/",
            img: "iata.png"
        },
        {
            id: 3,
            en: {
                caption: "PCA | Premier Cargo Alliance Network, a Worldwide Logistics Network for Independent Freight Forwarders, International Logistic Companies and Service Providers in the Logistics and Transportation Industry",
            },
            caption_url: "http://www.premiercargoalliance.com/listing/ecargo-world-estonia/",
            img: "PCAMainLogotiny.png"
        },
        {
            id: 4,
            en: {
                caption: "OWN | One World Network",
            },
            caption_url: "https://preview.mailerlite.com/k4w3k7",
            img: "own.png"
        },

        {
            id: 5,
            en: {
                caption: "Pangea Logistics Network",
            },
            caption_url: "https://pangea-network.com/freight-forwarder/ecargoworld-russia-llc/moscow/",
            img: "pangea.png"
        },
    ],

    shippers: 
    [
        {value: "aircargo", text: "Air Cargo",},
        {value: "postems", text: "Post/EMS",},
        {value: "container", text: "Container",},
        {value: "billoflading", text: "Bill Of Lading",},
        {value: "aduiepyle", text: "A. Duie Pyle",},
        {value: "aaacooper", text: "AAA Cooper",},
        {value: "abf", text: "ABF",},
        {value: "aff", text: "AFF",},
        {value: "americanlinehaul", text: "American Linehaul",},
        {value: "carlile", text: "Carlile",},
        {value: "centralfreightlines", text: "Central Freight Lines",},
        {value: "centraltransport", text: "Central Transport *",},
        {value: "cevalogistics", text: "CEVA Logistics",},
        {value: "clarke", text: "Clarke",},
        {value: "clearlane", text: "Clear Lane",},
        {value: "combinedexpress", text: "Combined Express *",},
        {value: "csatransportation", text: "CSA Transportation",},
        {value: "dayross", text: "Day &amp; Ross *",},
        {value: "daylighttransport", text: "Daylight Transport",},
        {value: "daytonfreight", text: "Dayton Freight",},
        {value: "dbschenkerusa", text: "DB Schenker USA",},
        {value: "dependable", text: "Dependable",},
        {value: "dhl", text: "DHL",},
        {value: "dohrntransfer", text: "Dohrn Transfer",},
        {value: "doubledexpress", text: "Double D Express",},
        {value: "dsv", text: "DSV",},
        {value: "ediexpress", text: "EDI Express",},
        {value: "estes", text: "Estes",},
        {value: "fedex", text: "Fedex",},
        {value: "forwardair", text: "Forward Air",},
        {value: "gls", text: "GLS",},
        {value: "hercules", text: "Hercules",},
        {value: "jpexpress", text: "JP Express *",},
        {value: "kuehnenagel", text: "Kuehne + Nagel",},
        {value: "landairexpress", text: "Land Air Express",},
        {value: "lynden", text: "Lynden",},
        {value: "maldivesports", text: "Maldives Ports",},
        {value: "manitoulintransport", text: "Manitoulin Transport",},
        {value: "midwestmotorexpress", text: "Midwest Motor Express",},
        {value: "newpenn", text: "New Penn",},
        {value: "olddominion", text: "Old Dominion",},
        {value: "panther", text: "Panther",},
        {value: "parcelforce", text: "Parcelforce",},
        {value: "philadelphiatrucklines", text: "Philadelphia Truck Lines",},
        {value: "pittohio", text: "PITT OHIO",},
        {value: "postnord", text: "Postnord",},
        {value: "purolator", text: "Purolator",},
        {value: "rlcarriers", text: "R+L Carriers",},
        {value: "reddaway", text: "Reddaway",},
        {value: "roadrunner", text: "Roadrunner",},
        {value: "saia", text: "Saia",},
        {value: "southeasternfreightlines", text: "Southeastern Freight Lines",},
        {value: "sterling", text: "Sterling",},
        {value: "taexpress", text: "TA Express",},
        {value: "tcs", text: "TCS",},
        {value: "tforcefreightcanada", text: "TForce Freight Canada",},
        {value: "tnt", text: "TNT",},
        {value: "total", text: "Total",},
        {value: "tstcfexpress", text: "TST-CF Express *",},
        {value: "ups", text: "UPS",},
        {value: "uslogistics", text: "US Logistics",},
        {value: "usps", text: "USPS",},
        {value: "vitran", text: "Vitran",},
        {value: "wardtrucking", text: "Ward Trucking",},
        {value: "xpologisticsltl", text: "XPO Logistics LTL",},
        {value: "yrc", text: "YRC",},
    ],
  },

  mutations: {
    set_lang (state, str_lang) {
      state.lang = str_lang
    },

  },
  actions: {
  },
  modules: {
  }
})
