export default {
  name: "home-page",
  data() {
    return {

    };
  },
  computed: {
    lang: function () {
        return this.$store.state.lang;
    },

  },

  methods: {

  },

  created() {

  },
};